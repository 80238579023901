import { Card, Elevation, FormGroup, Radio, Text } from '@blueprintjs/core';
import { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { DriverDataCard } from './DriverDataCard';
import PreferredDriverSelectByEmail from './PreferredDriverSelectByEmail';
import PreferredDriverSelectByName from './PreferredDriverSelectByName';

import { useField } from 'formik';
import {
  selectEstimateMatch,
  selectPreferredDriverType,
  setPreferredDriverType,
} from '../../../lib/reducers/estimateSlice';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../../lib/store';
import { selectUser } from '../../../lib/reducers/userSlice';
import {
  Driver,
  updatePreferredDriverById,
} from '../../../lib/actions/MatchAction';

type PreferredDriverSelectOptionProps = {
  label: string;
  caption: string;
  selected: boolean;
  onSelected: () => void;
};

export enum PreferredDriverOption {
  None,
  Recent,
  Email,
}

function PreferredDriverSelectOption({
  label,
  caption,
  selected,
  onSelected,
}: PreferredDriverSelectOptionProps) {
  return (
    <Col xs={4}>
      <Card
        interactive
        elevation={selected ? Elevation.THREE : Elevation.ONE}
        onClick={() => onSelected()}
        className={
          selected
            ? 'card left-aligned cardSelected preferredDriverSelectPadding'
            : 'card left-aligned preferredDriverSelectPadding'
        }
      >
        <Row>
          <Col sm={12}>
            <Radio
              label={label}
              type='checkbox'
              checked={selected}
              onChange={() => {}}
              className='cardTitle'
            />
          </Col>
          <Col sm={12}>
            <Text>{caption}</Text>
          </Col>
        </Row>
      </Card>
    </Col>
  );
}

type PreferredDriverSelectProps = {
  csvPreferredDriverId: string | null | undefined;
};

export function PreferredDriverSelect({
  csvPreferredDriverId,
}: PreferredDriverSelectProps) {
  const shipper = useSelector(selectUser);
  const match = useSelector(selectEstimateMatch);
  const preferredDriver = useAppSelector(
    state => state.estimate.match?.preferred_driver
  );
  const preferredDriverStoredType = useSelector(selectPreferredDriverType);
  const referringDriver = shipper?.referring_driver;
  const isNew = shipper?.is_new;
  const getSelectedOption = () => {
    if (isNew && referringDriver) {
      setPreferredDriverType(PreferredDriverOption.Recent);
      if (match) {
        updatePreferredDriverById(match.id, {
          platform: 'preferred_driver',
          preferred_driver_id: referringDriver.id,
        });
      }
      return PreferredDriverOption.Recent;
    } else {
      return PreferredDriverOption.None;
    }
  };

  const [selectedOption] = useState(getSelectedOption());
  const preferredDriverType = useSelector(selectPreferredDriverType);
  const dispatch = useAppDispatch();
  const [selectedPreferredDriver] = useState<Driver>();

  const [showDriverData, setShowDriverData] = useState<boolean>(
    (!!preferredDriver &&
      preferredDriverType === PreferredDriverOption.Recent) ||
      (!!referringDriver && selectedOption === PreferredDriverOption.Recent)
  );
  const [, , { setValue, setTouched }] = useField('preferred_driver_id');
  const [preferredDriverCardIndex, setPreferredDriverCardIndex] =
    useState(preferredDriverType);

  const handleChange = useCallback(
    (
      preferred_driver_id: string | undefined,
      preferredDriverType: PreferredDriverOption
    ) => {
      dispatch(setPreferredDriverType(preferredDriverType));
      setTouched(true, false);
      setValue(preferred_driver_id);
    },
    [dispatch, setTouched, setValue]
  );

  useEffect(() => {
    if (csvPreferredDriverId && csvPreferredDriverId !== preferredDriver?.id) {
      dispatch(setPreferredDriverType(PreferredDriverOption.Email));
      setPreferredDriverCardIndex(PreferredDriverOption.Email);
      handleChange(csvPreferredDriverId, PreferredDriverOption.Email);
    }
  }, [csvPreferredDriverId, dispatch, handleChange, preferredDriver?.id]);

  useEffect(() => {
    // Handle edge case where estimate already has a preferred driver when first visiting the screen
    // Our Redux doesn't persist offline so we have no way to tell which option they used, so default to Email
    if (
      !!preferredDriver &&
      preferredDriverStoredType === PreferredDriverOption.None
    ) {
      setPreferredDriverCardIndex(PreferredDriverOption.Email);
      handleChange(preferredDriver.id, PreferredDriverOption.Email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShowDriverData(
      !!preferredDriver &&
        preferredDriverStoredType !== PreferredDriverOption.None
    );
  }, [preferredDriver, preferredDriverStoredType, dispatch]);

  return (
    <>
      <div className='panelDivider' />
      <FormGroup
        style={{ display: 'grid' }}
        label='Preferred Driver'
        inline={true}
        labelFor='driver'
        labelInfo='(Optional)'
        className='u-push__top--lg'
      >
        <Row>
          <PreferredDriverSelectOption
            label='None'
            caption='Automatically assign a driver'
            selected={
              selectedOption === PreferredDriverOption.None &&
              preferredDriverType === PreferredDriverOption.None
            }
            onSelected={() => {
              setPreferredDriverCardIndex(PreferredDriverOption.None);
              handleChange('', PreferredDriverOption.None);
            }}
          />
          <PreferredDriverSelectOption
            label='Recent Drivers'
            caption="Choose from a driver you've used before"
            selected={
              selectedOption === PreferredDriverOption.Recent ||
              preferredDriverType === PreferredDriverOption.Recent
            }
            onSelected={() => {
              setPreferredDriverCardIndex(PreferredDriverOption.Recent);
              handleChange('', PreferredDriverOption.Recent);
            }}
          />
          <PreferredDriverSelectOption
            label='Email'
            caption='Choose any driver by their email address'
            selected={
              selectedOption === PreferredDriverOption.Email ||
              preferredDriverType === PreferredDriverOption.Email
            }
            onSelected={() => {
              setPreferredDriverCardIndex(PreferredDriverOption.Email);
              handleChange('', PreferredDriverOption.Email);
            }}
          />
        </Row>
        <Row>
          {(selectedOption === PreferredDriverOption.Recent ||
            preferredDriverType === PreferredDriverOption.Recent) && (
            <PreferredDriverSelectByName
              minimumVehicleClass={match?.vehicle_class}
              currentPreferredDriver={
                referringDriver
                  ? referringDriver
                  : selectedPreferredDriver
                  ? selectedPreferredDriver
                  : null
              }
              onSelect={selectedDriver => {
                handleChange(selectedDriver?.id, PreferredDriverOption.Recent);
              }}
            />
          )}
          {preferredDriverCardIndex === PreferredDriverOption.Email && (
            <PreferredDriverSelectByEmail
              minimumVehicleClass={match?.vehicle_class}
              currentPreferredDriver={preferredDriver}
              onSelect={selectedDriver => {
                handleChange(selectedDriver?.id, PreferredDriverOption.Email);
              }}
            />
          )}
        </Row>
        {preferredDriver && showDriverData && (
          <DriverDataCard
            driver={preferredDriver}
            minimal={preferredDriverStoredType === PreferredDriverOption.Email}
          />
        )}
      </FormGroup>
    </>
  );
}
