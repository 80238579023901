import { Col, Row } from 'react-flexbox-grid';
import { useSearchParams } from 'react-router-dom';
import MatchMap from './MatchMap';
import { Match, MatchState } from '../../lib/actions/MatchAction';
import CancelMatch from './CancelMatch';
import MatchPanel from './MatchPanel';
import RateMatch from './RateMatch';
import { useSelector } from 'react-redux';
import { selectUser } from '../../lib/reducers/userSlice';
import { getActiveEta, matchInState } from '../../lib/Utility';
import { Card, Icon } from '@blueprintjs/core';
import { useMemo } from 'react';
import moment from 'moment';

type MatchPanelProps = {
  match: Match;
};

export default function MatchView({ match }: MatchPanelProps) {
  const [searchParams] = useSearchParams();
  const rating = searchParams.get('rating');
  const user = useSelector(selectUser);
  const eta = useMemo(() => getActiveEta(match), [match]);

  const isMatchEnRoute = () => {
    if (!match.stops) return false;

    return matchInState(match, [
      MatchState.PickedUp,
      MatchState.ArrivedAtPickup,
      MatchState.EnRouteToPickup,
    ]);
  };

  const showRatingModal = () => {
    return matchInState(match, [MatchState.Completed, MatchState.Charged]);
  };

  const showCancelOption = () => {
    switch (match.state) {
      case 'pending':
      case 'scheduled':
      case 'assigning_driver':
      case 'preferred_driver_offer_not_accepted':
      case 'unable_to_pickup':
        return true;
      case 'accepted':
        return true;

      default:
        return false;
    }
  };

  const defaultRating = parseInt(rating || '') || null;

  return (
    <Row>
      <Col xs={12} lg={4} className='statusPane'>
        <MatchPanel match={match} />
      </Col>

      <Col xs={12} lg={8}>
        {eta && eta.arrive_at && (
          <Card className='flat-card'>
            <strong className='u-pad__right--sm u-text--primary'>ETA</strong>
            <span className='u-pad__right--sm'>
              <Icon
                icon='time'
                size={16}
                style={{ top: -1, position: 'relative' }}
                className='u-text--primary'
              />
              <span> {moment(eta.arrive_at).fromNow()}</span>
            </span>
            {eta.distance && (
              <span>
                <Icon
                  icon='route'
                  size={16}
                  style={{ top: -2, position: 'relative' }}
                  className='u-text--primary'
                />
                {eta.distance && <span> {eta.distance} mi away</span>}
              </span>
            )}
          </Card>
        )}
        <MatchMap match={match} isEnRoute={isMatchEnRoute()} />
        {user && user.id === match?.shipper?.id ? (
          <>
            {showRatingModal() && (
              <RateMatch defaultRating={defaultRating} match={match} />
            )}
            {showCancelOption() && <CancelMatch match={match} />}
          </>
        ) : (
          <div className='reviewBox'>
            {user ? (
              <p>
                This Match was created by another Shipper. Contact creator to
                cancel or rate this Match
              </p>
            ) : (
              <h2>You are not logged in</h2>
            )}
          </div>
        )}
      </Col>
    </Row>
  );
}
