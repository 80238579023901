import { useEffect } from 'react';
import { Card, Icon, ProgressBar, Text } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import { Match, MatchState } from '../../../lib/actions/MatchAction';
import {
  fetchFeatureFlag,
  selectFeatureFlag,
  selectUser,
} from '../../../lib/reducers/userSlice';
import {
  chunkArray,
  formatAddress,
  formatDateSuccinct,
  formatDateVerbose,
  formatDistance,
  formatMatchProgress,
  formatMatchStatus,
  formatPhoneNumberSimplify,
  formatTime,
  formatVehicle,
  matchInState,
} from '../../../lib/Utility';
import DateTimeCard from '../../DateTimeCard';
import { useAppDispatch } from '../../../lib/store';
import PreferredDriverPanel from '../../estimate/summary/PreferredDriverPanel';

type MatchStopDetailsProps = {
  match: Match;
};

function MatchStopDetails({
  match: { stops, dropoff_at, timezone },
}: MatchStopDetailsProps) {
  const firstStop = stops[0];
  if (stops.length === 1 && firstStop) {
    const { destination_address } = firstStop;
    return (
      <div>
        <p className='statusDescription'>DROPOFF </p>
        {destination_address.name && <h2>{destination_address.name}</h2>}
        <h2>{formatAddress(destination_address.formatted_address)}</h2>
        <DateTimeCard dateTime={dropoff_at} timezone={timezone} />
      </div>
    );
  } else {
    return (
      <div>
        <p className='statusDescription'>STOPS </p>
        <ol className='stopList'>
          {stops.map(({ id, destination_address, dropoff_by, state }) => (
            <li key={`stop-${id}-dropoff-by`}>
              {destination_address.name && <h2>{destination_address.name}</h2>}
              <h2>
                {formatAddress(destination_address.formatted_address)}
                <span className={`label label__state--${state} large`}>
                  {state}
                </span>
              </h2>
              <DateTimeCard dateTime={dropoff_by} timezone={timezone} />
            </li>
          ))}
        </ol>
      </div>
    );
  }
}

type MatchTimestampsProps = {
  match: Match;
};

function MatchTimestamps({ match }: MatchTimestampsProps) {
  const timestamps = matchInState(match, [
    MatchState.Canceled,
    MatchState.AdminCanceled,
  ])
    ? [{ title: 'CANCELED AT', timestamp: match.canceled_at }]
    : [
        { title: 'ACTIVATED AT', timestamp: match.activated_at },
        { title: 'ACCEPTED AT', timestamp: match.accepted_at },
        { title: 'PICKED UP AT', timestamp: match.picked_up_at },
        { title: 'COMPLETED AT', timestamp: match.completed_at },
      ];

  return (
    <>
      {chunkArray(timestamps, 2).map((timestamps, index) => (
        <div className='cardThreeColumn' key={index}>
          {timestamps.map(({ title, timestamp }, index) => (
            <div className='cardInset' key={index}>
              <div className='cardInset--data'>
                <p className='statusDescription'>{title}</p>
                {timestamp ? (
                  <>
                    <h2>{formatTime(timestamp, match.timezone)}</h2>
                    <p>{formatDateSuccinct(timestamp, match.timezone)}</p>
                  </>
                ) : (
                  <h2>-</h2>
                )}
              </div>
            </div>
          ))}
        </div>
      ))}
    </>
  );
}

type DetailsPanelProps = {
  match: Match;
  isActive: boolean;
};

export default function DetailsSection({ match, isActive }: DetailsPanelProps) {
  const user = useSelector(selectUser);
  const { driver, shipper } = match;
  const progress = formatMatchProgress(match);
  const dispatch = useAppDispatch();
  const hasPreferredDriverFeature = useSelector(
    selectFeatureFlag('preferred_driver')
  );

  useEffect(() => {
    dispatch(fetchFeatureFlag({ flag: 'preferred_driver' }));
  }, [dispatch]);

  const renderStatus = () => {
    if (
      match.platform === 'deliver_pro' ||
      match.platform === 'preferred_driver'
    ) {
      let status;
      switch (match.state) {
        case 'assigning_driver':
          status = 'Awaiting Preferred Driver';
          break;
        case 'accepted':
          status = 'Preferred Driver Accepted';
          break;
        default:
          status = formatMatchStatus(match.state);
      }
      return <h2 data-testid='current_status'>{status}</h2>;
    } else {
      // Marketplace match
      if (match.stops.length === 1 && match.stops[0]?.state === 'returned') {
        return <h2 data-testid='current_status'>Returned</h2>;
      } else {
        return (
          <h2 data-testid='current_status'>{formatMatchStatus(match.state)}</h2>
        );
      }
    }
  };

  const isMatchCanceled = (state: MatchState) => {
    switch (state) {
      case MatchState.Canceled:
      case MatchState.AdminCanceled:
        return true;
      default:
        return false;
    }
  };

  return isActive ? (
    <Card interactive={false}>
      {renderStatus()}

      <ProgressBar
        intent={progress.intent}
        value={progress.value}
        stripes={progress.stripes}
      />

      {hasPreferredDriverFeature && !isMatchCanceled(match.state) && (
        <PreferredDriverPanel match={match} />
      )}

      <div className='cardInset'>
        <div className='cardInset--icon'>
          <Icon icon='arrow-up' iconSize={20} />
        </div>
        <div className='cardInset--data'>
          <p className='statusDescription'>PICKUP</p>
          {match.origin_address.name && <h2>{match.origin_address.name}</h2>}
          <h2>{formatAddress(match.origin_address.formatted_address)}</h2>
          <DateTimeCard dateTime={match.pickup_at} timezone={match.timezone} />
        </div>
      </div>

      <div className='cardInset'>
        <div className='cardInset--icon'>
          <Icon icon='arrow-down' iconSize={20} />
        </div>
        <div className='cardInset--data'>
          <MatchStopDetails match={match} />
        </div>
      </div>

      {user && shipper && (
        <div className='cardInset'>
          <div className='cardInset--icon'>
            <Icon icon='box' iconSize={20} />
          </div>
          <div className='cardInset--data'>
            <p className='statusDescription'>SHIPPER</p>
            <h2>
              {shipper.first_name} {shipper.last_name}
            </h2>
            <p className='statusNote'>{shipper.email}</p>
          </div>
        </div>
      )}

      <div className='cardDivider' />

      {driver && (
        <div className='cardInset'>
          <div className='cardInset--icon'>
            <Icon icon='drive-time' iconSize={22} />
          </div>
          <div className='cardInset--data'>
            <p className='statusDescription'>
              YOUR DRIVER
              <span className='statusRight'>
                <a
                  href={`tel:${formatPhoneNumberSimplify(driver.phone_number)}`}
                >
                  <Icon icon='phone' iconSize={10} /> Call
                </a>{' '}
                &nbsp;{' '}
                <a
                  href={`sms:${formatPhoneNumberSimplify(driver.phone_number)}`}
                >
                  <Icon icon='mobile-phone' iconSize={10} /> Text
                </a>
              </span>
            </p>
            <h2 data-testid='driver_name'>
              {driver.first_name} {driver.last_name}
            </h2>
            <p className='statusNote'>
              {driver.vehicle.vehicle_year} {driver.vehicle.vehicle_make}{' '}
              {driver.vehicle.vehicle_model}
            </p>
            <p className='statusNote'>
              <a href={`tel:${formatPhoneNumberSimplify(driver.phone_number)}`}>
                {driver.phone_number}
              </a>
            </p>
          </div>
        </div>
      )}

      <div className='cardInset'>
        <div className='cardInset--icon'>
          <Icon icon='calendar' size={22} />
        </div>
        <div className='cardInset--data'>
          <p className='statusDescription'>
            MATCH #{match.shortcode || match.id}
          </p>
          <h2>{formatDateVerbose(match.inserted_at, match.timezone)}</h2>
        </div>
      </div>

      {match.accessorials.length > 0 && (
        <div className='cardInset'>
          <div className='cardInset--icon'>
            <Icon icon='cube-add' size={22} />
          </div>
          <div className='cardInset--data'>
            <p className='statusDescription'>OPTIONAL SERVICES</p>
            {match.accessorials.map(accessorial => (
              <Text
                className='label u-push__left--none u-push__right--xs u-push__bottom--xs'
                key={accessorial.id}
              >
                {accessorial.name}
              </Text>
            ))}
          </div>
        </div>
      )}

      <div className='cardThreeColumn'>
        <div className='cardInset'>
          <div className='cardInset--data'>
            <p className='statusDescription'>VEHICLE</p>
            <h2>{formatVehicle(match.vehicle_class)}</h2>
          </div>
        </div>

        <div className='cardInset'>
          <div className='cardInset--data'>
            <p className='statusDescription'>
              PRICE
              <span className='statusRight'>
                <span style={{ color: '#00F' }}>
                  <Icon icon='properties' iconSize={10} />
                </span>
                &nbsp;{' '}
              </span>
            </p>
            <h2>
              ${match.total_price ? match.total_price.toFixed(2) : '0.00'}
            </h2>
          </div>
        </div>

        <div className='cardInset'>
          <div className='cardInset--data'>
            <p className='statusDescription'>DISTANCE</p>
            <h2>{formatDistance(match.total_distance)}</h2>
          </div>
        </div>
      </div>

      <MatchTimestamps match={match} />
    </Card>
  ) : null;
}
