import { Button, Callout, InputGroup, Menu, MenuItem } from '@blueprintjs/core';
import { Formik, FormikHelpers } from 'formik';
import { Col, Row } from 'react-flexbox-grid';
import * as yup from 'yup';
import { ObjectSchema } from 'yup';
import { Driver } from '../../../lib/actions/MatchAction';
import { getPreferredDriverOptionsBy } from '../../../lib/actions/UserAction';
import { getErrorMessage } from '../../../lib/FraytRequest';
import { useEffect, useState } from 'react';
import { useDebouncer } from '../../../lib/Hooks';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../lib/reducers/userSlice';
import { selectEstimateMatch } from '../../../lib/reducers/estimateSlice';

export type SelectByNameValues = { name?: string };
const selectByNameSchema: ObjectSchema<SelectByNameValues> = yup
  .object()
  .shape({
    name: yup.string().optional(),
  });

type PreferredDriverSelectByNameProps = {
  minimumVehicleClass?: number;
  currentPreferredDriver?: Driver | null;
  onSelect?: (driver: Driver | undefined) => void;
  padding?: boolean;
};

export default function PreferredDriverSelectByName({
  currentPreferredDriver,
  onSelect,
  padding = true,
  minimumVehicleClass,
}: PreferredDriverSelectByNameProps) {
  const [showDriverOption, setShowDriverOption] = useState(false);

  const [suggestedDrivers, setSuggestedDrivers] = useState<Driver[]>();

  const [recentDrivers, setDriverList] = useState<Driver[]>([]);

  const [debounce, clearDebounce] = useDebouncer(1000);

  const shipper = useSelector(selectUser);

  const match = useSelector(selectEstimateMatch);

  useEffect(() => {
    if (shipper && shipper.id && match && match.id) {
      getPreferredDriverOptionsBy({
        type: 'suggested_for_match',
        query: match.id,
      }).then(response => {
        setSuggestedDrivers(response.data.data);
      });
    }
  }, [shipper, match]);

  const handleSubmit = async (
    values: SelectByNameValues,
    { setFieldError }: FormikHelpers<SelectByNameValues>
  ) => {
    const { name } = values;
    if (!name) return setShowDriverOption(false);

    try {
      const {
        data: { data: result },
      } = await getPreferredDriverOptionsBy({
        type: 'name',
        query: name,
      });
      if (result.length <= 0)
        throw new Error(
          'No FRAYT driver found with that name. Please try a different name.'
        );

      setDriverList(result);
      setShowDriverOption(true);
    } catch (e) {
      setFieldError('name', getErrorMessage(e));
      if (onSelect) onSelect(undefined);
    }
    clearDebounce();
  };

  const renderSuggestedDrivers = () => {
    if (suggestedDrivers && suggestedDrivers.length > 0)
      return suggestedDrivers.map(driver => (
        <MenuItem
          className='suggestedDriver'
          key={driver.id}
          text={`${driver.first_name} ${driver.last_name}`}
          label='suggested'
          labelClassName='suggestedDriverLabel'
          onClick={() => {
            setShowDriverOption(false);
            if (onSelect) onSelect(driver);
          }}
        />
      ));
  };

  const vehicleClassNameFromNumber = (vehicleClass: number) => {
    switch (vehicleClass) {
      case 1:
        return 'Car';
      case 2:
        return 'Midsize';
      case 3:
        return 'Cargo Van';
      case 4:
        return 'Box Truck';
    }
  };

  const renderVehicleLabel = (vehicleClass: number) => (
    <div
      style={{
        display: 'inline-block',
        backgroundColor: '#cdced0',
        borderRadius: '20px',
        padding: '0px 10px',
        margin: '0px 0px 0px 0px',
      }}
    >
      {vehicleClassNameFromNumber(vehicleClass)}
    </div>
  );

  const renderDrivers = () => {
    return (
      <Menu
        style={{
          width: '100%',
          maxHeight: '200px',
          overflowY: 'auto',
          position: 'relative',
          boxShadow: '0px 0px 5px grey',
        }}
      >
        {renderSuggestedDrivers()}
        {recentDrivers &&
          recentDrivers.map(driver => {
            return (
              <div style={{ display: 'flex' }} key={driver.id}>
                <MenuItem
                  disabled={
                    minimumVehicleClass != null &&
                    driver.vehicle.vehicle_class < minimumVehicleClass
                  }
                  text={`${driver.first_name} ${driver.last_name}`}
                  onClick={() => {
                    setShowDriverOption(false);
                    if (onSelect) onSelect(driver);
                  }}
                  labelElement={renderVehicleLabel(
                    driver.vehicle.vehicle_class
                  )}
                ></MenuItem>
              </div>
            );
          })}
      </Menu>
    );
  };

  return (
    <Col xs={12} className={padding ? 'u-push__top--lg' : ''}>
      <Row>
        <Col xs={12}>
          <Formik
            validateOnMount
            initialValues={{ name: '' } as SelectByNameValues}
            validationSchema={selectByNameSchema}
            onSubmit={handleSubmit}
          >
            {({ submitForm, handleChange, errors, handleSubmit }) => (
              <>
                <InputGroup
                  placeholder='driver name'
                  onClick={() => setShowDriverOption(true)}
                  name='name'
                  fill={true}
                  asyncControl={true}
                  onChange={e => {
                    handleChange(e);
                    debounce(handleSubmit);
                  }}
                  rightElement={
                    <Button
                      className='selectByNameButton'
                      icon='search'
                      minimal={true}
                      onClick={() => submitForm()}
                    />
                  }
                  onKeyDown={event => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      submitForm();
                    }
                  }}
                />
                {showDriverOption && (
                  <div className='selectByNamePopover'>{renderDrivers()}</div>
                )}
                {currentPreferredDriver && (
                  <Callout
                    className='preferredDriverSelectCallout'
                    intent='success'
                  >
                    Successfully assigned {currentPreferredDriver.first_name}{' '}
                    {currentPreferredDriver.last_name} (
                    {currentPreferredDriver.email}) as the preferred driver for
                    your order.
                  </Callout>
                )}
                {errors.name && (
                  <Callout
                    className='preferredDriverSelectCallout'
                    intent='danger'
                  >
                    {errors.name}
                  </Callout>
                )}
              </>
            )}
          </Formik>
        </Col>
      </Row>
    </Col>
  );
}
