import { Col, Row } from 'react-flexbox-grid';
import { selectUser } from '../../lib/reducers/userSlice';
import { useAppSelector } from '../../lib/store';
import { formatVehicle, formatVehicleImage } from '../../lib/Utility';

export function DeliverProDriverCard() {
  const driver = useAppSelector(selectUser)?.referring_driver;
  const driverVehicle = driver
    ? formatVehicle(driver?.vehicle.vehicle_class)
    : '';

  return driver ? (
    <Row>
      <Col md={12} lg={6}>
        <div className='deliverProDriverCard'>
          <div className='deliverProDriver'>
            <text className='driverTitle'>YOUR DRIVER</text>
            <text className='driverName'>{`${driver.first_name} ${driver.last_name}`}</text>
            {
              // FIXME: driver address is missing from referring driver
            }
            <text className='driverDetails'>{`{insert driver address here}`}</text>
            <text className='driverDetails'>{`${driver.vehicle.vehicle_year} ${driver.vehicle.vehicle_make} ${driver.vehicle.vehicle_model}`}</text>
          </div>
          <div className='divider'></div>
          <div className='deliverProVehicle'>
            <div className='cardImage'>
              <img
                className='deliverProVehicleImage'
                src={formatVehicleImage(driver.vehicle.vehicle_class)}
                alt='Frayt'
              />
            </div>
            <text className='vehicleText'>{`${driverVehicle}`}</text>
          </div>
        </div>
      </Col>
    </Row>
  ) : (
    <></>
  );
}
